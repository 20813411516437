import React, { useEffect, useContext, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { GlobalState } from "../Data/Context";
import { Navbar, Collapse, Nav, NavItem } from "reactstrap";
import logo from "../Assets/logo.png";
import { CapitalizeFirst } from "./Sidebar";
import { FaTimes, FaBars } from "react-icons/fa";
import LogoWhite from "../Assets/logo_light.png";
import LogoBlack from "../Assets/logo_dark.svg";
import { IoMenu, IoMicCircleOutline } from "react-icons/io5";
import { IoCloseCircleOutline } from "react-icons/io5";

const Header = () => {
  const { headerList } = useContext(GlobalState);
  let location = useLocation(),
    [isOpen, setIsOpen] = useState(false),
    [isShadow, setIsShadow] = useState(false),
    toggle = () => {
      setIsOpen(!isOpen);
    },
    navigate = useNavigate();

  useEffect(() => {
    document.title = CapitalizeFirst(
      `Viscio ${location.pathname.split("/").join(" ").substring(1)}`
    );
  }, [location.pathname]);

  let handleScroll = () => {
    window.onscroll = () => {
      if (window.scrollY > 100) setIsShadow(true);
      else setIsShadow(false);
    };
  };

  useEffect(() => {
    handleScroll();
  }, []);
  let classCss = (list) =>
    `menuItem text-decoration-none ${
      location.pathname.length > 1 &&
      list.url.length > 1 &&
      location.pathname.includes(list.url)
        ? "fw-bold headerActive"
        : location.pathname.length === 1 && list.url.length === 1
        ? "fw-bold headerActive"
        : "text-dark"
    } text-capitalize hug`;

  return (
    <Navbar
      expand="lg"
      sticky="top"
      className={`container-fluid px-3 header bg-white headerScroll w-100 ${
        isShadow ? "shadow" : ""
      }`}
      light
    >
      <Link to="/">
        <img src={logo} alt="Viscio" className="logo" />
      </Link>
      {isOpen ? (
        <FaTimes
          color="white"
          onClick={toggle}
          className="navbar-close rounded d-lg-none textColor2"
        />
      ) : (
        <FaBars
          color="white"
          onClick={toggle}
          className="navbar-close rounded d-lg-none textColor2"
        />
      )}
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mx-auto d-flex align-items-center" navbar>
          {headerList.map((list, index) => (
            <NavItem key={index} className="mx-2 mx-lg-3 my-2 my-lg-auto">
              {list.type === "button" ? (
                <span
                  onClick={() => {
                    navigate("/login");
                    if (isOpen) setIsOpen(false);
                  }}
                  className={`${classCss(list)} myCursor`}
                >
                  {list.name}
                </span>
              ) : (
                <Link
                  to={list.url}
                  onClick={() => (isOpen ? setIsOpen(false) : null)}
                  className={classCss(list)}
                >
                  {list.name}
                </Link>
              )}
            </NavItem>
          ))}
        </Nav>
        <div className="d-flex align-items-center justify-content-center me-lg-0">
          <NavItem className="myCursor list-unstyled me-lg-4 me-2">
            <Link
              to={"/login"}
              onClick={() => (isOpen ? setIsOpen(false) : null)}
              className="text-decoration-none btn btn-primary1 text-capitalize px-4 hug"
            >
              log in
            </Link>
          </NavItem>
          <NavItem className="myCursor list-unstyled ms-lg-4 ms-2">
            <Link
              to={"/register"}
              onClick={() => (isOpen ? setIsOpen(false) : null)}
              className="text-decoration-none btn btn-outline-primary1 text-capitalize px-4 hug"
            >
              sign up
            </Link>
          </NavItem>
        </div>
      </Collapse>
    </Navbar>
  );
};

export const NewHeaderMain = ({ bg, color, light }) => {
  const location = useLocation(),
    [menu, setMenu] = useState(false),
    path = location.pathname,
    navigate = useNavigate(),
    links = [
      { name: "home", path: "/" },
      { name: "about us", path: "/about" },
      { name: "contact us", path: "/contact" },
      { name: "reviews", path: "/review" },
      { name: "order", path: "/order" },
      { name: "price checker", path: "/price-checker" },
    ];
  return (
    <div
      style={{
        background: bg || "transparent",
        color: color || "white",
      }}
      className="tw-w-full tw-h-32 tw-z-30 tw-relative"
    >
      <div className="container tw-h-full tw-flex tw-justify-between tw-items-center">
        {light ? <Logo /> : <LogoDark />}
        <IoMenu
          className="tw-block lg:tw-hidden"
          onClick={() => setMenu(true)}
          size={25}
          color={light ? "white" : "#001B87"}
        />
        <div className="tw-h-20 tw-bg-[#F5FFED4D] tw-hidden tw-rounded-full lg:tw-flex tw-items-center tw-px-6 tw-gap-4">
          {links?.map((link) => (
            <div
              onClick={() => navigate(link?.path)}
              style={{
                fontWeight: path === link?.path ? "600" : "400",
                color: path === link?.path && "#001B87",
                borderBottom:
                  path === link?.path ? "2px solid #001B87" : "none",
              }}
              className="tw-h-14 tw-cursor-pointer tw-flex tw-justify-center tw-items-center poppins tw-capitalize tw-px-5 tw-rounded-lg tw-text-xl"
            >
              <span> {link?.name}</span>
            </div>
          ))}
        </div>
        <div className="lg:tw-flex tw-hidden tw-gap-4">
          <div
            onClick={() => navigate("/login")}
            className="tw-h-16 tw-flex tw-justify-center tw-items-center tw-cursor-pointer tw-bg-white tw-w-40 tw-rounded-full tw-text-xl tw-font-normal tw-text-[#001B87]"
          >
            Login
          </div>
          <div
            onClick={() => navigate("/register")}
            style={{
              borderWidth: "1px 2px 2px 1px",
              borderStyle: "solid",
              borderColor: "#BDC9FB",
            }}
            className="tw-h-16 tw-flex tw-justify-center tw-items-center tw-cursor-pointer tw-w-44 tw-bg-[#001B87] tw-text-xl tw-font-normal tw-text-white tw-rounded-full"
          >
            Get Started
          </div>
        </div>
      </div>
      {menu && (
        <div
          data-aos="zoom-in"
          className="tw-absolute tw-top-0 tw-w-full tw-p-6 rounded-1 shadow-lg tw-bg-white"
        >
          <div className="tw-flex tw-justify-between tw-items-center">
            <LogoDark />
            <IoCloseCircleOutline
              onClick={() => setMenu(false)}
              size={40}
              color="#001b87"
            />
          </div>
          <div className="tw-mt-6 tw-space-y-4">
            {links?.map((l) => (
              <h6
                onClick={() => navigate(l.path)}
                className="tw-text-xl tw-font-medium tw-capitalize poppins tw-text-[#001b87]"
              >
                {l?.name}
              </h6>
            ))}
          </div>
          <div className="tw-flex tw-mt-8 tw-gap-4">
            <button
              onClick={() => navigate("/login")}
              className="lg:tw-h-16 tw-py-3   tw-w-44 tw-bg-[#001B87] lg:tw-text-xl tw-font-normal tw-text-white tw-rounded-full"
            >
              Login
            </button>
            <button
              onClick={() => navigate("/register")}
              style={{
                borderWidth:
                  "1px, 2px, 2px, 1px;border-style: solid;border-color: #BDC9FB",
              }}
              className="lg:tw-h-16 tw-py-3   tw-w-44 tw-bg-[#001B87] lg:tw-text-xl tw-font-normal tw-text-white tw-rounded-full"
            >
              Get Started
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export const Logo = () => {
  const navigate = useNavigate();
  return (
    <div>
      <img
        onClick={() => navigate("/")}
        src={LogoWhite}
        alt=""
        className="tw-cursor-pointer lg:tw-h-28 tw-h-16"
      />
    </div>
  );
};

export const LogoDark = () => {
  const navigate = useNavigate();
  return (
    <div>
      <img
        onClick={() => navigate("/")}
        src={LogoBlack}
        alt=""
        className="tw-cursor-pointer lg:tw-h-28 tw-h-16"
      />
    </div>
  );
};

export default Header;
