import React from 'react'

function Statitics() {

    let stats=[
        {name:"customers",
         value:"400+"},
         {name:"trips",
         value:"1312+"},
         {name:"active vendors",
         value:"200+"},
         {name:"operations base",
         value:"15+"},
         {name:"availability",
         value:"24/7"},
    ]
  return (
    <div className='stat tw-mx-[10px] tw-p-[10px] lg:tw-flex lg:tw-justify-around lg:tw-items-center'>
       {stats.map((item)=>(
        <div className='border rounded-md w-[200px]  tw-text-center tw-p-[10px] tw-m-4'>
            <h4 className='tw-font-bold tw-mb-2'>{item.name}</h4>
            <h4 className='tw-font-bold'><b>
                {item.value}
                </b>
                </h4>
        </div>
       ))}
       
    </div>
  )
}

export default Statitics