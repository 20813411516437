import { useState, useEffect } from "react";

import hero from "../Assets/hero.jpeg";
import pic3 from "../Assets/pic3.jpeg";
import pic4 from "../Assets/pic4.jpeg";

const treeImages = [hero, pic3, pic4];

export default function ThreeImageSlider() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % treeImages.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="tw-absolute tw-h-full tw-w-full tw-top-0 tw-left-0 overflow-hidden">
      {treeImages.map((src, index) => (
        <div
          key={src}
          className="tw-absolute inset-0 tw-w-full tw-h-full tw-transition-opacity tw-duration-1000 tw-ease-in-out tw-top-0 tw-left-0"
          style={{ opacity: index === currentImageIndex ? 1 : 0 }}
        >
          <img
            src={src}
            alt={`Tree ${index + 1}`}
            className="tw-w-full tw-h-full"
            style={{ objectFit: "cover" }}
            priority
          />
          <div className="tw-absolute tw-inset-0  tw-z-10 tw-bg-black/50" />
        </div>
      ))}

      <div className="container tw-h-full tw-flex tw-items-center tw-justify-center tw-relative tw-z-20">
        <div className="lg:tw-w-3/4 tw-w-full lg:tw-mt-10 tw-mx-auto tw-px-4 lg:tw-px-0">
          <h1 className="lg:tw-text-[56px] tw-text-[32px] lg:tw-leading-[1.2] tw-leading-tight tw-text-white tw-font-semibold poppins tw-text-center">
            We make{" "}
            <span className="gradient-text2 lg:tw-text-[56px] tw-text-[32px] tw-font-semibold">
              Farm2Market
            </span>{" "}
            Agro-Logistics Efficient, Predictable and Affordable
          </h1>
          <p className="lg:tw-text-2xl tw-text-lg tw-mt-5 tw-font-normal tw-text-white poppins tw-text-center tw-max-w-3xl tw-mx-auto">
            Empowering Farmers and Agro-Logistics Providers through Seamless
            Technology-driven Collaboration
          </p>
          <form
            action=""
            onSubmit={(e) => e?.preventDefault()}
            className="tw-mt-[40px]"
          >
            <div className="tw-relative tw-h-[64px] md:tw-h-20 lg:tw-w-1/2 tw-w-full tw-mx-auto">
              <input
                type="text"
                style={{
                  border: "1px solid #001B8799",
                }}
                placeholder="Input tracking ID"
                className="tw-rounded-full tw-pl-6 tw-h-full tw-text-base md:tw-text-xl tw-text-[#111114] poppins placeholder:tw-font-light tw-font-normal tw-w-full tw-bg-white"
              />
              <button className="tw-h-[40px] md:tw-h-14 tw-absolute tw-top-3 tw-right-3 lg:tw-right-5 tw-w-32 lg:tw-w-44 tw-bg-[#001b87]  tw-text-base lg:tw-text-xl tw-text-white tw-rounded-full poppins">
                Track
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
