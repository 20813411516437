import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "@redux-devtools/extension";
import thunk from "redux-thunk";
import rootReducer from "./Reducer";

const initialState = {};

const middleware = [thunk];

const Store = createStore(
	rootReducer,
	initialState,
	applyMiddleware(...middleware)
);

export default Store;
