import React, { useEffect } from "react";
import { Container } from "reactstrap";
import { OrderListNonAuth } from "../Components/Orders/OrderList";
import "../Styles/AuthStyles.css";
import { NewHeaderMain } from "../Components/Header";

const Order = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="tw-w-full">
      <NewHeaderMain light={false} color={"#333333"} />
      <Container className="py-5">
        <section className="py-5">
          <OrderListNonAuth />
        </section>
      </Container>
    </section>
  );
};

export default Order;
