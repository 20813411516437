import React from 'react';

const FourSidedStar = ({ fill = '#767676', ...props }) => (
  <svg
    width="66"
    height="66"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M32.504 0.88791C32.5783 0.305606 33.4217 0.305604 33.496 0.887908L37.024 28.5433C37.0528 28.7693 37.2307 28.9472 37.4567 28.976L65.1121 32.504C65.6944 32.5783 65.6944 33.4217 65.1121 33.496L37.4567 37.024C37.2307 37.0528 37.0528 37.2307 37.024 37.4567L33.496 65.1121C33.4217 65.6944 32.5783 65.6944 32.504 65.1121L28.976 37.4567C28.9472 37.2307 28.7693 37.0528 28.5433 37.024L0.88791 33.496C0.305606 33.4217 0.305604 32.5783 0.887908 32.504L28.5433 28.976C28.7693 28.9472 28.9472 28.7693 28.976 28.5433L32.504 0.88791Z"
      fill={fill}
    />
  </svg>
);

export default FourSidedStar;
