import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Customers=({image})=> {
      
  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 1000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 4,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  
  };

    let testimonies = [
		{
			image,
			name: "Zinny's outfit",
			comment: `Has been my go-to zone for logistics and i have never been disappointed.`,
		},
		{
			image,
			name: "Mrs Eki",
			comment: `Love the fact that i have access to numerous offers and can negotiate.`,
		},
		{
			image,
			name: "Esthy Collections",
			comment: `Don't worry, i'm stuck here and never leaving`,
		},
		{
			image,
			name: "Mr Oluwatobi",
			comment: `Happy about the speed of delivery and support team availability.`,
		},
		{
			image,
			name: "Ademola Ajala",
			comment: `One of the most functional and detailed website I have seen in a while.`,
		},
		{
			image,
			name: "Zoe Fashion Hub",
			comment: `This will really save a lot of us small business owners from Logistics people wahala. At least we can hold them accountable now.`,
		},
		{
			image,
			name: "Ifeoluwa",
			comment: `This is fantastic, Thanks for the simplicity and the ease of use`,
		},
	];

  return (
	<>
  <div className=''>
  <h1 className='tw-text-3xl tw-font-bold tw-ml-5 text-[#022288]'>What Our Customers Say</h1>
  <p className='tw-mt-3 tw-ml-5 tw-mb-5'>Take a dive into the reviews of what our user have to say about their experience at with Viscio Express. </p>
  </div>
	<div className='w-3/4 m-auto  '> 
	  <div className='mt-6'>
		<Slider {...settings}>
		{testimonies.map((item)=>(
			<div className='tw-shadow-lg tw-rounded-md tw-w-[200px] tw-m-[30px] tw-h-[220px] tw-p-6'>
            <img src={item.image} className='tw-m-auto tw-w-[60px] tw-h-[60px] tw-rounded-full'/>
            <p className=' tw-m-[10px]'>{item.comment}</p>
            <h4 className='tw-semibold  tw-overline tw-text-center tw-mt-4'>{item.name}</h4>
        </div>
		 
		))}

		</Slider>
	  </div>
	</div>
		</>
  )
}
