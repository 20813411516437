import React from "react";
import { Container } from "reactstrap";
import MainOrder from "../../Components/Orders";

const Orders = () => {
	return (
		<Container className="px-lg-5 pt-3 pt-lg-0">
			<MainOrder />
		</Container>
	);
};

export default Orders;
