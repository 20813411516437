import React, { useState,useEffect } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';
import image1 from "./V1.jpg"
import image2 from "./V3.jpg"
import image3 from "./Vi1.jpg"

function Slider() {
  const [curr,setCurr]=useState(0)
  
  const slides = [
    {
      url:image1
    },
    {
      url: image2,
    }, 
    {
      url: image3,
    },
  ];

  // const [currentIndex, setCurrentIndex] = useState(0);

  // const prevSlide = () => {
  //   const isFirstSlide = currentIndex === 0;
  const prev = () =>
  setCurr((curr) => (curr === 0 ? slides.length - 1 : curr - 1));
const next = () =>
  setCurr((curr) => (curr === slides.length - 1 ? 0 : curr + 1));

useEffect(() => {
  // if (!autoSlide) return;
  const slideInterval = setInterval(next,5000);
  return () => clearInterval(slideInterval);
}, []);



  // const goToSlide = (slideIndex) => {
  //   setCurrentIndex(slideIndex);
  // };/

  return (
    <div className='tw-max-w-[700px] tw-h-[650px] tw-w-full tw-m-auto tw-py-28 tw-px-2 tw-relative tw-group'> 
      <div className='tw-hidden tw-group-hover:tw-block tw-absolute tw-top-[50%] tw--translate-x-0 tw-translate-y-[-50%] tw-left-5 tw-text-2xl tw-rounded-full tw-p-2 tw-bg-black/20 tw-text-white tw-cursor-pointer'>
        <BsChevronCompactLeft onClick={prev} size={30} />
      </div>
    
      <div
        style={{ backgroundImage: `url(${slides[curr].url})` }}
        className='tw-w-full tw-h-full tw-rounded-2xl tw-bg-center tw-bg-cover tw-duration-500'
      ></div>
    
      <div className='tw-hidden group-hover:tw-block tw-absolute tw-top-[50%] tw--translate-x-0 tw-translate-y-[-50%] tw-left-5 tw-text-2xl tw-rounded-full tw-p-2 tw-bg-black/20 tw-text-white tw-cursor-pointer'>
        <BsChevronCompactLeft onClick={prev} size={30} />
      </div>
    
      <div className='tw-hidden group-hover:tw-block tw-absolute tw-top-[50%] tw--translate-x-0 tw-translate-y-[-50%] tw-right-5 tw-text-2xl tw-rounded-full tw-p-2 tw-bg-black/20 tw-text-white tw-cursor-pointer'>
        <BsChevronCompactRight onClick={next} size={30} />
      </div>
      <div className='tw-flex tw-top-2 tw-justify-center'>
        {slides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            // onClick={() => goToSlide(slideIndex)}
            className='tw-text-2xl tw-cursor-pointer'
          >
            <RxDotFilled />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Slider;