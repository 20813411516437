import React from "react";
import { Link } from "react-router-dom";
import { NewHeaderMain } from "../Components/Header";

function Review() {
  return (
    <section className="tw-w-full">
      <NewHeaderMain light={false} color={"#333333"} bg={"white"} />
      <div className="reviews">
        <div className="tw-text-center">
          <h1 className="tw-text-center tw-text-white tw-mt-5">
            Rider's Reviews
          </h1>
          <p className="tw-text-center tw-text-white">
            Are you a logistics Operators,looking to employ a new driver/rider{" "}
            <br></br>
            for your vehicle? Find his past work records here
          </p>
          <Link to="/review">
            <button
              className=" box  tw-px-6 tw-text-center tw-bg-transparent tw-text-white tw-py-4 tw-rounded-lg tw-m-auto"
            >
              {" "}
              See Reviews
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Review;
