import { FaTimes, FaBars } from "react-icons/fa";
import logo from "../Assets/logo.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

export default function NewHeader() {
  // const { headerList } = useContext(GlobalState);
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const login = () => {
    navigate("/login");
    if (isOpen) setIsOpen(false);
  };
  const register = () => {
    navigate("/register");
    if (isOpen) setIsOpen(false);
  };
  const [activeTab, setActiveTab] = useState("home");
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/") {
      setActiveTab("home");
    } else if (location.pathname === "/about") {
      setActiveTab("about");
    } else if (location.pathname === "/contact") {
      setActiveTab("contact");
    } else if (location.pathname === "/faqs") {
      setActiveTab("faqs");
    } else if (location.pathname === "/order") {
      setActiveTab("order");
    } else if (location.pathname === "/login") {
      setActiveTab("login");
    }
  }, [location]);
  return (
    <div className="tw-w-full tw-py-12 tw-bg-[#ffffff] tw-font-[poppins] tw-sticky tw-top-0 tw-z-20 tw-mb-2">
      <nav className="">
        <div className="tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8 ">
          <div className="tw-flex tw-items-center tw-justify-between tw-h-10">
            {/* navbar logo */}
            <Link to="/" className="tw-mt-3">
              <img src={logo} className="tw-h-16" alt="logo" />
            </Link>
            {/* end */}
            {/* navbar links */}
            {/* end */}
            {/*  */}

            <div className=" tw-hidden md:tw-flex  tw-font-[Poppins] tw-justify-between tw-items-center  tw-gap-8">
              <Link
                to="/"
                className={`${
                  activeTab == "home" ? "tw-text-[#022288]" : "tw-text-black"
                } tw-no-underline  `}
              >
                Home
              </Link>
              <Link
                to="/about"
                className={`${
                  activeTab == "about" ? "tw-text-[#022288]" : "tw-text-black"
                } tw-no-underline `}
              >
                About Us
              </Link>
              <Link
                to="/contact"
                className={`${
                  activeTab == "contact" ? "tw-text-[#022288]" : "tw-text-black"
                }  tw-no-underline`}
              >
                Contact Us
              </Link>
              {/* <Link  to="/review" 
             className={`${activeTab =="review" ? "tw-text-[#022288]":"tw-text-black"} tw-no-underline`}
            
            >Reviews
            </Link> */}
              <Link
                to="/faqs"
                className={`${
                  activeTab == "faqs" ? "tw-text-[#022288]" : "tw-text-black"
                } tw-no-underline`}
              >
                FAQ's
              </Link>
              <Link
                to="/order"
                className={`${
                  activeTab == "order" ? "tw-text-[#022288]" : "tw-text-black"
                }  tw-no-underline`}
              >
                Order
              </Link>
              <Link
                to="/login"
                className={`${
                  activeTab == "login" ? "tw-text-[#022288]" : "tw-text-black"
                } tw-font-[Poppins] tw-no-underline`}
              >
                Price Checker
              </Link>
            </div>
            <div className="tw-hidden md:tw-flex tw-justify-evenly tw-items-center tw-gap-3 tw-mt-2 tw-mr-[120px] ">
              <button
                className="tw-bg-white tw-text-[#022288] tw-rounded tw-border tw-px-8 tw-py-2 "
                onClick={login}
              >
                Log in
              </button>
              <button
                className="tw-bg-[#022288] tw-border  tw-text-white tw-rounded tw-px-8 tw-py-2 "
                onClick={register}
              >
                Get Started
              </button>
            </div>
            {/*  */}

            {isOpen ? (
              <div className="md:tw-hidden">
                <FaTimes
                  onClick={toggle}
                  className="tw-text-[#022288] tw-text-2xl "
                />
              </div>
            ) : (
              <div className="md:tw-hidden tw-border tw-rounded tw-bg-[#022288] tw-p-2">
                <FaBars
                  onClick={toggle}
                  className="tw-text-[#ffffff] tw-text-2xl"
                />
              </div>
            )}
          </div>
        </div>
      </nav>
      {isOpen ? (
        <div className="md:tw-hidden tw-mt-5 tw-bg-white tw-w-full tw-flex tw-justify-center tw-items-center">
          <div className=" tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-4 ">
            <Link
              to="/"
              onClick={() => setIsOpen(false)}
              className={`${
                activeTab == "home" ? "tw-text-[#022288]" : "tw-text-black"
              } tw-no-underline `}
            >
              Home
            </Link>
            <Link
              to="/about"
              onClick={() => setIsOpen(false)}
              className={`${
                activeTab == "about" ? "tw-text-[#022288]" : "tw-text-black"
              } tw-no-underline `}
            >
              About Us
            </Link>
            <Link
              to="/contact"
              onClick={() => setIsOpen(false)}
              className={`${
                activeTab == "contact" ? "tw-text-[#022288]" : "tw-text-black"
              }  tw-no-underline`}
            >
              Contact Us
            </Link>
            {/* <Link  to="/review"  onClick={()=>setIsOpen(false)}
             className={`${activeTab =="review" ? "tw-text-[#022288]":"tw-text-black"}  tw-no-underline`}           
            >Reviews
            </Link> */}
            <Link
              to="/faqs"
              onClick={() => setIsOpen(false)}
              className={`${
                activeTab == "faqs" ? "tw-text-[#022288]" : "tw-text-black"
              }  tw-no-underline`}
            >
              FAQ's
            </Link>
            <Link
              to="/order"
              onClick={() => setIsOpen(false)}
              className={`${
                activeTab == "order" ? "tw-text-[#022288]" : "tw-text-black"
              } tw-no-underline`}
            >
              Order
            </Link>
            <Link
              to="/login"
              onClick={() => setIsOpen(false)}
              className={`${
                activeTab == "login" ? "tw-text-[#022288]" : "tw-text-black"
              }  tw-no-underline`}
            >
              Price Checker
            </Link>

            <div className="tw-flex tw-justify-evenly tw-items-center tw-gap-3 tw-mt-2">
              <button
                className="tw-bg-white tw-text-[#022288] tw-rounded tw-px-4 tw-py-2 "
                onClick={login}
              >
                Log in
              </button>
              <button
                className="tw-bg-[#022288] tw-border  tw-text-white tw-rounded tw-px-4 tw-py-2 "
                onClick={register}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
