import React from 'react'
import { GiMineTruck } from "react-icons/gi";




export  const WhatWedo=()=> {
  return (
    <div className='tw-mt-[60px]'>
     <h1 className='tw-text-3xl tw-ml-[20px] tw-text-[#022288] '>What We do</h1>
    <div className='flex tw-mt-5 tw-mb-[20px]'>

        <div className='tw-w-[200px] tw-p-6 tw-border tw-h-[180px] tw-shadow-md tw-rounded'>
        <div className='tw-p-4 tw-bg-slate-100 tw-m-2 tw-rounded tw-w-[60px] '>

        <GiMineTruck className='tw-text-3xl'/>
        </div>
        <p className='tw-font-bold tw-ml-2 tw-mt-3 '>We Minimise Empty Miles for general cargo logistics</p>


        </div>

        <div className='tw-w-[200px] tw-p-6 tw-border tw-h-[180px] tw-shadow-md tw-rounded'>
        <div className='tw-p-4 tw-bg-slate-100 tw-m-2 tw-rounded tw-w-[60px] tw-m-'>

        <GiMineTruck className='tw-text-3xl'/>
        </div>
        <p className='tw-font-bold tw-ml-2 tw-mt-3 '>Re-engineer Food Security by reducing Agro-Logistics costs</p>

        </div>
        <div className='tw-w-[200px] tw-p-6 tw-border tw-h-[180px] tw-shadow-md tw-rounded'>
        <div className='tw-p-4 tw-bg-slate-100 tw-m-2 tw-rounded tw-w-[60px] tw-m-'>

        <GiMineTruck className='tw-text-3xl'/>
        </div>
        <p className='tw-font-bold tw-ml-2 tw-mt-3 '>Cut down avoidable Carbon Emission by un-maximized mobility assets.</p>


        </div>

    </div>


    </div>
  )
}