import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { GlobalState } from "./Data/Context";
import PageRender from "./PageRender";
import Home2 from "./Pages/home";
import Home3 from "./Views/home";
import { DefaultHeader, Footer, Sidebar } from "./Components";
import SocketClient from "./SocketClient";
import NewHome from "./Screens/new-home";
import { TOKEN } from "./Data/Actions/ActionType";
// import { NewHeaderMain } from "./Components/Header";

const Routers = () => {
  const token = localStorage.getItem(TOKEN);
  const { auth } = useContext(GlobalState);
  return (
    <div className={auth?.isAuth ? "side-header" : ""}>
      <div id={token ? "main-wrapper" : ""}>
        <ToastContainer />
        {token ? (
          <>
            <DefaultHeader />
            <SocketClient />
            <Sidebar />
          </>
        ) : (
          <>
            {/* <Header /> */}
            {/* <NewHeader /> */}
            {/* <NewHeaderMain /> */}
          </>
        )}
        <Routes>
          <Route
            path="/"
            element={
              token && auth?.user?.type === "user" ? (
                <Home2 />
              ) : token && auth?.user?.type === "vendor" ? (
                <Home3 />
              ) : (
                <NewHome />
              )
            }
          />
          <Route path="/:page" element={<PageRender />} />
          <Route path="/:page/:id" element={<PageRender />} />
          <Route path="/:page/:id/:step" element={<PageRender />} />
        </Routes>
        {token ? <></> : <Footer />}
      </div>
    </div>
  );
};

export default Routers;
