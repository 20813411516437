import React, { createElement, useEffect } from "react";
import { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ErrorPage } from "./Components";
import { GlobalState } from "./Data/Context";
import { Loader } from "./Utils";
import { TOKEN } from "./Data/Actions/ActionType";

const generatePage = (pageName, folder) => {
	console.log(folder)
	console.log(pageName)
	const component = () => require(`./${folder}/${pageName}`).default;

	try {
		return createElement(component());
	} catch (error) {
		return <ErrorPage />;
	}
};

const PageRender = () => {
	const { auth, errors } = useContext(GlobalState);
	const { page, id, step } = useParams()
	const token = localStorage.getItem(TOKEN);

	const navigate = useNavigate();
	console.log(page)

	useEffect(() => {
		if (token) {
			if (page === "login" || page === "register") {
				navigate("/");
			}
		}
		if (!token) {
			if (errors?.errorText?.includes("jwt")) {
				navigate("/login");
			}
		}
	}, [page, errors?.errorText]);

	if (auth?.token && auth?.isAuthLoading) return <Loader />;

	let pageName = "";
	if (step) {
		pageName = `${page}/${id}/${"[id]"}`;
	} else if (id) {
		pageName = `${page}/[id]`;
	} else {
		pageName = `${page}`;
	}

	return generatePage(
		pageName,
		auth?.isAuth
			? auth?.user?.type === "user"
				? "Pages"
				: auth?.user?.type === "vendor"
					? "Views"
					: "Screens"
			: "Screens"
	);
};

export default PageRender;
