import React from 'react'
import { Link } from 'react-router-dom'

export default function AboutUs() {
  return (
    <div className='tw-flex tw-justify-center tw-items-center'>
        <div className='tw-text-center tw-p-[20px]'>

        <h1 className='tw-text-2xl tw-text-center tw-mt-2 tw-text-[#022288]'>About Us </h1>
      <p className='tw-text-justify tw-mt-4'>We make back-loading logistics profitable for agro-logistics providers and enable farmers/load <br></br>aggregators move their produce from farm to market affordably and predictably.The African logistics<br></br> scene is riddled with infrastructure gaps,high operating costs and overly fragmented activities.</p>
        
        <p className='tw-text-justify tw-mt-2 tw-mb-[30px]'>Viscio Express platform serves as a reliable collaboration tool for various logistics agents across<br></br>
        multiple locations leveraging on technology tools and a smart operations framework.</p>
          <Link to="/about" className='tw-mt-6'>

            <span className=' tw-w-[200px] tw-text-center tw-shadow-[#022288] tw-px-[20px] tw-py-3 tw-text-[#022288] tw-semibold tw-mt-5 tw-rounded tw-shadow-md'>learn more</span>
             
            </Link>
        </div>
    </div>
  )
}
