import React from "react";
import { NewHeaderMain } from "../Components/Header";
import Partner1 from "../Assets/august.svg";
import Partner2 from "../Assets/WhatsApp Image 2024-08-10 at 19.50.48 1.svg";
import Partner3 from "../Assets/abc.svg";
import Partner4 from "../Assets/fez.svg";
import Partner5 from "../Assets/chisco.svg";
import Partner6 from "../Assets/abc2.svg";
import ReliableIcon from "../Assets/reliablilityicon2.svg";
import NetworkIcon from "../Assets/chart.svg";
import ReachIcon from "../Assets/programming-arrows.svg";
import ShieldIcon from "../Assets/shield.svg";
import { FaArrowRight } from "react-icons/fa";
import Lagos from "../Assets/lagos.jpeg";
import Avatar from "../Assets/avatar.svg";
import Kaduna from "../Assets/kaduna.jpeg";
import Oyo from "../Assets/oyo.jpeg";
import Calabar from "../Assets/calabar.jpeg";
import Kano from "../Assets/kano.jpeg";
import Makurdi from "../Assets/makurdi.jpeg";
import Plateau from "../Assets/plateau.jpeg";
import AkwaIbom from "../Assets/akwa ibom.jpeg";
import Appstore from "../Assets/appstore.svg";
import PlayStore from "../Assets/playstore.svg";
import Phone from "../Assets/phone.png";
import DownloadBubbleImage from "../Assets/download-bubble.png";
import DownloadBgImage from "../Assets/download-bg.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Mousewheel } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import FourSidedStar from "../Assets/icons/FourSidedStar";
import ThreeImageSlider from "../Components/TwoImageSlider";

const NewHome = () => {
  const partners = [Partner1, Partner2, Partner3, Partner4, Partner5, Partner6];
  return (
    <div className="tw-w-full tw-overflow-hidden tw-relative">
      {/* new bg */}
      <div className="tw-w-full lg:tw-h-[120vh] tw-h-screen tw-relative">
        <div className="tw-absolute tw-top-0 tw-left-0 tw-right-0">
          <NewHeaderMain light={true} />
        </div>
        <ThreeImageSlider />
      </div>
      <div className="tw-w-full tw-bg-white lg:tw-h-36 tw-py-16 lg:tw-py-0">
        <div className="container tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center tw-h-full">
          <div>
            <h2 className="tw-text-xl lg:tw-text-2xl tw-font-medium inter tw-text-primary tw-mb-6 lg:tw-mb-0">
              Our Partners
            </h2>
          </div>
          <div className="tw-flex tw-flex-wrap tw-justify-center lg:tw-flex-row tw-gap-6 tw-items-center">
            {partners?.map((p, index) => (
              <img key={index} src={p} alt="" className="tw-h-10 lg:tw-h-14" />
            ))}
          </div>
        </div>
      </div>
      <WhySection />
      <CoverageSection />
      <DrivingSection />
      <TestimonySection />
      <DownloadSection />
    </div>
  );
};

const WhySection = () => {
  const Arr = [
    {
      title: "Unmatched reliability",
      bg: "#65AEE81A",
      desc: "With 1780+ successful trips, we ensure your agricultural products reach their destination safely and on time.",
      icon: ReliableIcon,
      color: "#242424",
    },
    {
      title: "Extensive network",
      bg: "#008080",
      desc: "Serving 650+ satisfied customers and working with 300+ active vendors, we connect you with a vast and trusted supply chain.",
      icon: NetworkIcon,
    },
    {
      title: "nationwide reach",
      bg: "#073042",
      desc: "Operating from 17+ strategic bases, we cover key agricultural regions, ensuring broad and efficient service.",
      icon: ReachIcon,
    },
    {
      title: "24/7 Availability",
      bg: "#0080801A",
      desc: "We're always on, providing round-the-clock logistics support to keep your business moving forward without delays.",
      icon: ShieldIcon,
      color: "#242424",
    },
  ];
  return (
    <section className="tw-w-full tw-py-16 lg:tw-py-24 tw-bg-[#F3FFF8] px-4 lg:px-0">
      <div className="container tw-px-4 lg:tw-px-0">
        <h2 className="lg:tw-text-5xl tw-text-3xl tw-text-primary tw-font-semibold mont">
          Why Choose Us
        </h2>
        <div className="tw-mt-8 tw-grid lg:tw-grid-cols-2 tw-gap-8 lg:tw-gap-14">
          {Arr?.map((it, index) => (
            <div
              key={index}
              style={{
                background: it?.bg,
                color: it?.color || "white",
              }}
              className="tw-rounded-3xl tw-p-6 lg:tw-p-8 tw-h-[250px] lg:tw-h-[500px] tw-relative"
            >
              <h3 className="lg:tw-text-3xl tw-text-xl tw-capitalize tw-font-semibold mont tw-max-w-sm">
                {it?.title}
              </h3>
              <p className="tw-mt-4 lg:tw-mt-5 lg:tw-text-xl tw-text-base tw-font-normal inter tw-w-full lg:tw-w-3/4">
                {it?.desc}
              </p>
              <div className="tw-absolute tw-bottom-5 tw-right-6">
                <img src={it?.icon} alt="" className="tw-w-16 lg:tw-w-auto" />
              </div>

              <FourSidedStar
                className="tw-absolute lg:tw-bottom-20 lg:tw-left-20 tw-bottom-10 tw-left-10 lg:tw-scale-100 tw-scale-50"
                fill="#8DC0C0"
              />
              <FourSidedStar
                className="tw-absolute lg:tw-bottom-5 lg:tw-left-5 tw-bottom-0 tw-left-0 lg:tw-scale-100 tw-scale-50"
                fill="#8DC0C0"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const CoverageSection = () => {
  const Arr = [
    {
      img: Lagos,
      name: "lagos",
      desc: "Lagos is the epicenter of our operations, ensuring swift and reliable agricultural logistics for bustling markets and businesses.",
    },
    {
      img: Kaduna,
      name: "kaduna",
      desc: "Kaduna connects rural produce to urban demand, streamlining agricultural supply chains.",
    },
    {
      img: Oyo,
      name: "oyo",
      desc: "Rich in agricultural heritage, Oyo is a key hub for our logistics, bridging local farms with wider markets across the country.",
    },
    {
      img: Calabar,
      name: "calabar",
      desc: "Calabar serves as our gateway to the southeast, facilitating efficient distribution of agricultural products across the region.",
    },
    {
      img: Kano,
      name: "kano",
      desc: "As a major commercial center in the north, Kano plays a crucial role in our network, connecting farmers to markets across Nigeria.",
    },
    {
      img: Makurdi,
      name: "makurdi",
      desc: "Makurdi, known as the food basket of the nation, is integral to our operations in distributing fresh produce nationwide.",
    },
    {
      img: AkwaIbom,
      name: "akwa ibom",
      desc: "Akwa Ibom enhances our coastal logistics, providing vital links for agricultural exports and imports in the south-south region.",
    },
    {
      img: Plateau,
      name: "plateau",
      desc: "Plateau, with its unique climate, is a key node in our network for distributing specialized agricultural products across Nigeria.",
    },
  ];
  return (
    <section className="tw-w-full tw-py-16 lg:tw-py-20 px-4 lg:px-0">
      <div className="container tw-px-4 lg:tw-px-0">
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between lg:tw-items-start">
          <div>
            <div className="tw-h-8 tw-w-[150px] md:tw-w-44 tw-flex tw-rounded-full tw-justify-center tw-items-center tw-bg-[#E6E8F3]">
              <span className="tw-text-xs md:tw-text-sm poppins tw-font-semibold tw-text-[#001B87]">
                We got you covered
              </span>
            </div>
            <h2 className="tw-mt-6 lg:tw-text-5xl tw-text-3xl tw-font-semibold tw-text-primary mont">
              Our Coverage
              <br /> Cities
            </h2>
          </div>
          <div className="tw-mt-4 lg:tw-mt-0">
            <p className="lg:tw-text-2xl tw-text-base tw-text-[#282828] tw-max-w-xl tw-font-light poppins">
              Our commitment to serve you constantly propels us to enlarge our
              coverage locations to ensure efficient service delivery and
              proximity to our priority channels.
            </p>
            <div
              style={{
                border: "1px solid #001B87",
              }}
              className="tw-mt-6 tw-h-[40px] tw-w-[150px] md:tw-h-14 tw-cursor-pointer md:tw-w-52 tw-flex tw-justify-center tw-gap-5 tw-items-center tw-rounded-full"
            >
              <span className="tw-text-sm md:tw-text-xl lg:tw-text-2xl tw-font-normal inter tw-text-[#001B87]">
                Learn more
              </span>
              <FaArrowRight color="#001B87" />
            </div>
          </div>
        </div>

        <Swiper
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            480: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          className="mySwiper tw-mt-12 tw-w-full tw-h-full tw-pb-32"
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          modules={[Pagination, Autoplay, Mousewheel]}
        >
          {Arr?.map((it, index) => (
            <SwiperSlide key={index} className="tw-mb-20">
              <div>
                <div className="rounded-5 overflow-hidden">
                  <img
                    src={it?.img}
                    alt=""
                    className="tw-h-64 lg:tw-h-80 tw-w-full tw-object-cover"
                  />
                </div>
                <div className="tw-mt-6">
                  <h5 className="lg:tw-text-2xl tw-text-xl tw-font-semibold tw-text-primary mont tw-capitalize">
                    {it?.name}
                  </h5>
                  <p className="lg:tw-text-xl tw-text-sm tw-font-normal tw-text-[#282828] inter">
                    {it?.desc}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

const DrivingSection = () => {
  const sections = [
    { name: "customers", value: "650+" },
    { name: "trips", value: "1780" },
    { name: "active vendors", value: "300+" },
    { name: "operations base", value: "17+" },
    { name: "availability", value: "24/7" },
  ];
  return (
    <section className="tw-bg-[#040711] tw-w-full tw-py-16 lg:tw-py-32 tw-relative px-4 lg:px-0">
      <FourSidedStar className="tw-absolute lg:tw-top-5 lg:tw-left-5 tw-top-10 tw-left-0 lg:tw-scale-100 tw-scale-[.4]" />
      <FourSidedStar className="tw-absolute lg:tw-top-24 lg:tw-left-20 tw-top-20   tw-left-8 lg:tw-scale-100 tw-scale-[.4]" />
      <div className="container tw-px-4 lg:tw-px-0">
        <h2 className="lg:tw-text-6xl tw-text-3xl tw-text-center  tw-font-semibold poppins tw-text-white">
          Driving{" "}
          <span className="gradient-text2 lg:tw-text-6xl tw-text-3xl tw-font-semibold poppins">
            Agriculture
          </span>
          <p className="lg:tw-text-6xl tw-text-3xl tw-text-center tw-font-semibold poppins tw-text-white lg:tw-mt-5">
            Forward
          </p>
        </h2>
        <p className="lg:tw-text-2xl tw-text-base tw-font-normal tw-mt-6 poppins tw-text-center tw-w-full lg:tw-w-5/6 tw-mx-auto tw-text-white">
          Over 1,780 Successful Trips and Counting, Serving 650+ Customers with
          24/7 Availability Across 17+ Locations and Partnering with 300+ Active
          Vendors
        </p>
        <div className="tw-mt-12 tw-w-full tw-bg-[#FFFFFF33] lg:tw-h-40 tw-rounded-2xl tw-px-4 lg:tw-px-8 tw-py-8 lg:tw-py-0 tw-flex tw-flex-col tw-space-y-5 lg:tw-space-y-0 lg:tw-flex-row tw-items-center tw-justify-between">
          {sections?.map((section, index) => (
            <div
              key={index}
              style={{
                boxShadow: "0px 2px 2px 0px #00000026",
                border: "0.5px solid #FFFFFFCC",
              }}
              className="tw-h-32 tw-w-full lg:tw-w-auto tw-rounded-2xl tw-flex tw-items-center tw-justify-center tw-px-4 lg:tw-px-8"
            >
              <div>
                <h5 className="tw-text-lg lg:tw-text-xl tw-capitalize tw-text-center tw-font-normal tw-text-[#FFFFFFCC] poppins">
                  {section?.name}
                </h5>
                <h2 className="tw-text-xl lg:tw-text-2xl tw-text-center tw-font-semibold poppins gradient-text">
                  {section?.value}
                </h2>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const TestimonySection = () => {
  const testimonies = [
    {
      Avatar,
      firstName: "Zinny's",
      lastName: "outfit",
      text: "Has been my go-to zone for logistics and i have never been disappointed.",
    },
    {
      Avatar,
      firstName: "Mrs",
      lastName: "Eki",
      text: "Love the fact that i have access to numerous offers and can negotiate.",
    },
    {
      Avatar,
      firstName: "Esthy",
      lastName: "Collections",
      text: "Don't worry, i'm stuck here and never leaving",
    },
    {
      Avatar,
      firstName: "Mr",
      lastName: "Oluwatobi",
      text: "Happy about the speed of delivery and support team availability.",
    },
    {
      Avatar,
      firstName: "Ademola",
      lastName: "Ajala",
      text: "One of the most functional and detailed website I have seen in a while.",
    },
    {
      Avatar,
      firstName: "Zoe",
      lastName: "Fashion Hub",
      text: "This will really save a lot of us small business owners from Logistics people wahala. At least we can hold them accountable now.",
    },
    {
      Avatar,
      firstName: "Ifeoluwa",
      lastName: "",
      text: "This is fantastic, Thanks for the simplicity and the ease of use",
    },
    {
      Avatar,
      firstName: "Ejowoke",
      lastName: "Hilary",
      text: "Viscio Express has been a game-changer for me! I get produce in market timely, and at affordable fee. I have more customers now because i can sell cheaper too. I'm hooked!",
    },
    {
      Avatar,
      firstName: "TRUTH",
      lastName: "Agro",
      text: "I've tried several delivery services, but Viscio Express stands out. Their farm-to-table approach ensures freshness, and their logistics team is efficient. I've saved time and money. 5 stars!",
    },
  ];
  return (
    <section className="tw-w-full tw-bg-[#F5F7FF] tw-py-8 lg:tw-py-24 px-4 lg:px-0">
      <div className="container tw-px-4 lg:tw-px-0">
        <div className="tw-h-8 tw-w-44 tw-flex tw-rounded-full tw-justify-center tw-items-center tw-bg-[#E6E8F3]">
          <span className="tw-text-sm poppins tw-font-semibold tw-text-[#001B87]">
            Testimonials
          </span>
        </div>
        <div className="tw-mt-8">
          <h2 className="lg:tw-text-5xl tw-text-3xl tw-font-medium poppins tw-text-[#333333]">
            What our customers say
          </h2>
          <p className="tw-mt-6 tw-max-w-2xl lg:tw-text-xl tw-text-base tw-font-normal tw-text-[#292929]">
            Take a dive into the reviews of what our user have to say about
            their experience at with Viscio Express
          </p>
          <Swiper
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              480: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            className="mySwiper tw-mt-12 tw-w-full tw-h-full tw-pb-32"
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            modules={[Pagination, Autoplay, Mousewheel]}
          >
            {testimonies.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  style={{
                    boxShadow: "0px 4px 40px 0px #2626261A",
                  }}
                  data-aos="zoom-in"
                  className="tw-rounded-2xl tw-mb-20 tw-mx-auto lg:tw-mx-0 bg-white tw-p-6 lg:tw-p-8 tw-w-full lg:tw-w-96 tw-h-[350px]"
                >
                  <div className="tw-bg-red-500 tw-size-20 tw-rounded-full mx-auto tw-flex tw-flex-col ">
                    <p className="tw-text-white tw-text-2xl tw-font-semibold tw-uppercase m-auto">
                      {item.firstName[0]}
                      {item.lastName[0]}
                    </p>
                    {/* <img
                      data-aos="zoom-in"
                      src={item.Avatar}
                      alt={`Testifier ${item.name}`}
                      className="img-fluid tw-mx-auto imgTestimony"
                    /> */}
                  </div>
                  <p
                    className="tw-text-base lg:tw-text-lg tw-my-4 tw-font-light poppins tw-text-[#292929] tw-text-center"
                    data-aos="zoom-in"
                  >
                    {item.text}
                  </p>
                  <div className="my-2 border-top tw-w-1/3 mx-auto borderColor"></div>
                  <h6
                    data-aos="zoom-in"
                    className="tw-capitalize tw-text-center"
                  >
                    {item.firstName} {item.lastName}
                  </h6>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export const DownloadSection = () => {
  return (
    <section className="tw-w-full tw-py-20 lg:tw-py-40 tw-bg-white px-4 lg:px-0">
      <div className="container tw-relative tw-px-4 lg:tw-px-0">
        <div
          style={{
            boxShadow:
              "0px 0px 0px 0px #0000001A, " +
              "-9px 7px 25px 0px #0000001A, " +
              "-35px 28px 45px 0px #00000017, " +
              "-80px 64px 61px 0px #0000000D, " +
              "-141px 114px 73px 0px #00000003, " +
              "-221px 178px 79px 0px #00000000",
          }}
          className="tw-w-full tw-h-auto lg:tw-h-[448px] tw-bg-[#001B87] tw-rounded-[30px] tw-flex tw-items-center tw-px-6 lg:tw-px-10 tw-py-10 lg:tw-py-0 tw-relative"
        >
          <img
            src={DownloadBubbleImage}
            alt=""
            className="tw-absolute -tw-top-28 tw-left-0 tw-max-w-[358px] tw-w-full tw-h-full tw-object-cover"
          />
          <img
            src={DownloadBgImage}
            alt=""
            className="tw-absolute -tw-bottom-24 -tw-right-28 tw-max-w-[324px] tw-w-full tw-h-full tw-max-h-[343px] tw-object-cover"
          />

          <div className="tw-text-center md:tw-text-left">
            <h2 className="tw-text-white tw-text-3xl lg:tw-text-6xl tw-font-semibold poppins">
              Download Viscio Express
            </h2>
            <p className="tw-text-white tw-text-lg lg:tw-text-xl tw-mt-6 tw-font-light poppins">
              To Send your package and Become a driver
            </p>
            <div className="tw-mt-8 tw-flex tw-flex-row tw-gap-6 tw-items-center">
              <img src={Appstore} alt="" className="tw-h-[48px] lg:tw-h-full" />
              <img
                src={PlayStore}
                alt=""
                className="tw-h-[48px] lg:tw-h-full"
              />
            </div>
          </div>
        </div>
        <img
          src={Phone}
          alt=""
          className="tw-absolute tw-hidden lg:tw-block tw-right-20 tw-bottom-[-90px] tw-z-10"
        />
      </div>
    </section>
  );
};

export default NewHome;
